<template>
  <ion-page>
    <Main>
      <template #subHeader>
        <ion-toolbar>
          <ion-row v-if="order" class="ion-padding-p ion-padding-vertical">
            <ion-col size="1" class="ion-no-padding">
              <span>
                <ion-icon
                  @click="back"
                  class="back-arrow"
                  :icon="arrowBack"
                ></ion-icon>
              </span>
            </ion-col>
            <ion-col class="ion-no-padding">
              <ion-row class="ion-no-padding">
                <ion-col size="12" class="ion-no-padding">
                  <p class="title">
                    {{ product.name }} ({{ $filters.folio(product.id) }})
                  </p>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </template>
      <template #content>
        <ion-row class="cards-container ion-padding-p  ion-padding-vertical">
          <ion-col class="ion-no-padding">
            <ion-card class="ion-no-padding ion-no-margin">
              <ion-card-content>
                <ion-row class="col-title">
                  <ion-col size="12" class="ion-no-padding">
                    <p class="title ion-padding-horizontal">
                      Seleccione la calidad del Artículo
                    </p>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12" class="ion-no-padding">
                    <ion-list>
                      <ion-item>
                        <ion-label>Primera</ion-label>
                        <ion-checkbox
                          color="primary"
                          slot="start"
                          @update:modelValue="primera = $event"
                          :modelValue="primera"
                        >
                        </ion-checkbox>
                      </ion-item>
                      <ion-item>
                        <ion-label>Segunda</ion-label>
                        <ion-checkbox
                          color="primary"
                          slot="start"
                          @update:modelValue="primera = !$event"
                          :modelValue="!primera"
                        >
                        </ion-checkbox>
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>
                <template v-if="product.label">
                  <ion-row class="col-title">
                    <ion-col size="12" class="ion-no-padding">
                      <p class="title ion-padding-horizontal">
                        Ingrese el numero de etiqueta
                      </p>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="ion-no-padding">
                      <ion-item>
                        <ion-input v-model="product.label"></ion-input>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </template>
                <ion-row class="col-title">
                  <ion-col size="12" class="ion-no-padding">
                    <p class="title ion-padding-horizontal">
                      Comentarios del Artículo
                    </p>
                  </ion-col>
                </ion-row>
                <ion-row class="ion-padding-horizontal">
                  <ion-col>
                    <ion-label>Agregue un comentario</ion-label>
                    <ion-textarea
                      v-model="product.comment"
                      rows="4"
                      auto-grow="true"
                    ></ion-textarea>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </template>
      <template #subFooter>
        <ion-toolbar>
          <ion-row v-if="order" class="ion-padding-p ion-padding-vertical">
            <ion-col size="12" class="ion-no-padding">
              <ion-button
                type="button"
                class="ion-margin-bottom"
                expand="block"
                color="primary"
                @click="confirm(false)"
              >
                Confirmar Registro
              </ion-button>
            </ion-col>
            <ion-col size="12" class="ion-no-padding">
              <ion-button
                v-if="
                  (product.review + 1) >= (product.total > 2 ? 3 : product.total) &&
                    product.armado == 1
                "
                type="button"
                class="ion-margin-bottom outlined primary"
                expand="block"
                @click="confirm(true)"
              >
                Confirmar Registro y revisión de orden
              </ion-button>
            </ion-col>
            <ion-col
              v-if="product.armado == 0"
              size="12"
              class="ion-no-padding"
            >
              <ion-button
                type="button"
                class="ion-margin-bottom outlined warning"
                expand="block"
                @click="confirmDelProd"
              >
                Desechar producto
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </template>
    </Main>
  </ion-page>
</template>

<script>
import useOrder from "@/use/useOrder";
import {
  IonPage,
  IonRow,
  IonCol,
  IonIcon,
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonCheckbox,
  IonTextarea,
  IonToolbar,
  IonButton,
  IonCardContent,
  alertController,
} from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import useApi from "@/use/useApi";
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import Main from "@/components/system/main.vue";

export default {
  name: "Order detail",
  components: {
    Main,
    IonPage,
    IonRow,
    IonCol,
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonCheckbox,
    IonTextarea,
    IonToolbar,
    IonButton,
    IonCardContent,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    const { params } = useRoute();
    const { order } = useOrder();
    const product = ref({});
    const primera = ref(true);

    function back() {
      router.back();
    }

    const { post } = useApi();
    async function getProduct() {
      const data = await post(
        "app/calidad/get",
        {
          id: params.id,
        },
        "Cargando..."
      );
      if (data === null) {
        const alert = await alertController.create({
          class: "warning",
          header: "Error",
          message: "Se genero un error en servidor",
        });
        await alert.present();
      } else {
        if (data.error === false) {
          product.value = data.product ? data.product : {};
          primera.value = product.value.segunda == 1 ? false : true;
        }
      }
    }
    getProduct();

    async function confirm(done = false) {
      const data = await post(
        "app/calidad/confirm",
        {
          id: params.id,
          comment: product.value.comment,
          primera: primera.value,
          label: product.value.label,
          done: done,
        },
        "Validando..."
      );
      if (data === null) {
        const alert = await alertController.create({
          class: "warning",
          header: "Error",
          message: "Se genero un error en servidor",
        });
        await alert.present();
      } else {
        if (data.error === false) {
          const alert = await alertController.create({
            class: "success",
            header: "Exito!",
            message: data.message,
          });
          await alert.present();
          const done = await alert.onDidDismiss();
          if (done) {
            back();
          }
        }
      }
    }

    async function delProd() {
      const data = await post(
        "app/calidad/del",
        {
          id: params.id,
          comment: product.value.comment,
        },
        "..."
      );
      if (data === null) {
        const alert = await alertController.create({
          class: "warning",
          header: "Error",
          message: "Se genero un error en servidor",
        });
        await alert.present();
      } else {
        if (data.error === false) {
          const alert = await alertController.create({
            class: "success",
            header: "Exito!",
            message: data.message,
          });
          await alert.present();
          const done = await alert.onDidDismiss();
          if (done) {
            back();
          }
        }
      }
    }

    async function confirmDelProd() {
      const alert = await alertController.create({
        class: "success",
        header: "Eliminar producto",
        message: "¿Deséa continuar dando de baja el registro?",
        buttons: [
          {
            text: "Cancelar",
            handler: () => {
              console.log("Cancel");
            },
          },
          {
            text: "Continuar",
            handler: () => {
              delProd();
            },
          },
        ],
      });
      await alert.present();
    }

    return {
      order,
      product,
      arrowBack,
      primera,
      back,
      getProduct,
      confirmDelProd,
      confirm,
    };
  },
};
</script>

<style>
ion-card {
  border-radius: 15px;
  border: 1px solid var(--ion-color-primary);
  box-shadow: none;
  font-size: 12px;
}
ion-card-content.card-content-md {
  padding-left: 4px;
  padding-right: 4px;
}
ion-card-title {
  color: var(--ion-color-medium);
  font-size: 14px;;
}
ion-toolbar {
  --background: var(--ion-color-light);
}
ion-button::part(native) {
  text-transform: none;
  font-size: 15px;
}
ion-textarea {
  border: 2px solid var(--ion-color-light);
  border-radius: 8px;
}
.col-title {
  background: var(--ion-color-light);
}
.title.ion-padding-horizontal {
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--ion-color-dark);
}
</style>
